import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import PageHeader from "../../../../layouts/pageHeader/PageHeader";
import PageContent from "../../../../layouts/pageContent/PageContent";
import { RegulationContent } from "../../pages";
import { RegulationViewHeader } from "../../headers/regulationViewHeader/RegulationViewHeader";
import { IRegulation } from "./Regulation.interface";
import { useNotifier, useRootStore, useSequentialPromises } from "../../../../../../hooks";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AutocompleteModel,
  RegulationContentDto,
  RegulationContentQuestionDto,
  RegulationDto,
} from "../../../../../../api";
import {
  checkRegulationContentType,
  checkRegulationContentTypeIsDraft,
  findId,
} from "../../../../../../helpers/pathname";
import { handleCountQuestions } from "../../pages/helpers";
import { errorKeys } from "../../../../forms/createRegulatiomForm/consts";
import { resetIds, validateTest } from "../../../../forms/createTestsForm/helpers";
import { api } from "../../../../../../services";
import { regulationContentTypeKeys } from "../../misc/headerTools";
import { UserSelectionRule } from "../../../../../../api/types/userSelectionRule";
import { SettingUpAccessDialog } from "../../../../dialogs/settingUpAccessDialog/SettingUpAccessDialog";
import { keysRulesFor } from "../../../../dialogs/settingUpAccessDialog/SettingUpAccessDialog.interface";
import { PassingRegulationTestDialog } from "../../../../dialogs/passingRegulationTestDialog/PassingRegulationTestDialog";
import { CustomConfirmDialog } from "../../../../dialogs/customConfirmDialog/СustomConfirmDialog";
import { SelectApproverDialog } from "../../../../dialogs/selectApproverDialog/SelectApproverDialog";
import { GlobalAuthorizedContext } from "../../../../../../contexts/globalAuthorizedContext";
import { Card, Empty, Icon, IconButton } from "../../../../../uiKit";
import { observer } from "mobx-react-lite";
import { RegulationContext } from "../../../../../../contexts/RegulationPage/RegulationContext";
import { RoleSelectorDto } from "../../../../../../api/models/RoleSelectorDto";
import { defineAllow } from "./helpers";
import { allowsRegulationKeys } from "../../misc/consts";
import {
  AddingRegulationsToSectionDialog
} from "../../../../dialogs/addingRegulationsToSectionDialog/AddingRegulationsToSectionDialog";
import {
  RegulationAccessControlDialog
} from "../../../../dialogs/regulationAccessControlDialog/RegulationAccessControlDialog";

import { ISelectItem } from "../../../../../uiKit/select/SelectView";
import { usePagingWithController } from "../../../../../../hooks/usePaging";


export const Regulation: React.FC<IRegulation> = observer((props) => {
  const globalAuthorizedContext = useContext(GlobalAuthorizedContext);
  const {
    breadcrumbsList,
    navigationMenuItem,
    fetchNavigationMenuItem,
    isLoading,
    setIsLoading
  } = useContext(RegulationContext);
  const { breadcrumbsStore, authStore, regulationStore } = useRootStore();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const notifier = useNotifier();

  const location = useLocation();

  const testRef = useRef<{ test: RegulationContentQuestionDto[]; hasChanges: boolean }>({
    test: [],
    hasChanges: false,
  });

  const [regulation, setRegulation] = useState<RegulationDto>();

  // const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingRejectBtn, setIsLoadingRejectBtn] = useState<boolean>(false);
  const [isLoadingApproveBtn, setIsLoadingApproveBtn] = useState<boolean>(false);
  const [isLoadingSubmitApproval, setIsLoadingSubmitApproval] = useState<boolean>(false);
  const [isLoadingAddToSectionBtn, setIsLoadingAddToSectionBtn] = useState<boolean>(false);
  const [isLoadingSaveChangesBtn, setIsLoadingSaveChangesBtn] = useState<boolean>(false);
  const [isLoadingDeleteBtn, setIsLoadingDeleteBtn] = useState<boolean>(false);
  const [isLoadingStudyBtn, setIsLoadingStudyBtn] = useState<boolean>(false);

  const [isOpenAssignForStudyDialog, setIsOpenAssignForStudyDialog] = useState<boolean>(false);
  const [isOpenAccessSettingsDialog, setIsOpenAccessSettingsDialog] = useState<boolean>(false);
  const [isOpenFindSectionDialog, setIsOpenFindSectionDialog] = useState<boolean>(false);
  const [isOpenTestPassDialog, setIsOpenTestPassDialog] = useState<boolean>(false);
  const [isOpenSelectApproverDialog, setIsOpenSelectApproverDialog] = useState<boolean>(false);
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState<boolean>(false);

  const [currentContent, setCurrentContent] = useState<RegulationContentDto>();
  const [draftContent, setDraftContent] = useState<RegulationContentDto>();

  const [hasDraftChanges, setHasDraftChanges] = useState<boolean>(false);
  const [hasDraftTestChanges, setHasDraftTestChanges] = useState<boolean>(false);

  const [isForceReload, setIsForceReload] = useState<boolean>(false);

  const [editableTest, setEditableTest] = useState<RegulationContentQuestionDto[]>([]);

  const [selectionSection, setSelectionSection] = useState<AutocompleteModel | undefined>(undefined);

  const [title, setTitle] = useState<string>("");

  // const [isEditable, setIsEditable] = useState<boolean>(false);

  const isEditable = regulationStore.getRegulationIsEditable;
  const setIsEditable = (isEditable: boolean) => regulationStore.setRegulationIsEditable(isEditable);

  const isDraft = checkRegulationContentTypeIsDraft(location.search);
  const regulationContentType = checkRegulationContentType(location.search);
  // const isCreator : boolean = regulation?.createdByUser?.id == authStore?.getInitialInfo?.identity?.id;

  // const testIsPassed: boolean = isPassedTest(regulation, authStore?.getInitialInfo?.identity?.id);
  const testIsPassed = useMemo(() => !!regulation?.isStudied, [regulation]);
  const countQuestions: number = handleCountQuestions(regulation?.activeContent ?? { questions: [] });

  const promisesListRef = useRef(useSequentialPromises());
  // const isCreator: boolean = (authStore?.getInitialInfo?.identity?.id === regulation?.createdByUserId);

  const handleOpenAssignForStudyDialog = useCallback(() =>
      setIsOpenAssignForStudyDialog(true)
    , []);
  const handleCloseAssignForStudyDialog = useCallback((isReload) => {
    if (isReload) {
      setIsForceReload(true);
    }
    setIsOpenAssignForStudyDialog(false);
  }, []);

  const handleCloseAccessSettingsDialog = useCallback(() => setIsOpenAccessSettingsDialog(false), []);
  const handleOpenAccessSettingsDialog = useCallback(() =>
    setIsOpenAccessSettingsDialog(true)
    , []);

  const handleCloseFindSectionDialog = useCallback(() => setIsOpenFindSectionDialog(false), []);
  const handleOpenFindSectionDialog = useCallback(() => setIsOpenFindSectionDialog(true), []);

  const handleOpenSelectApproverDialog = useCallback(() => setIsOpenSelectApproverDialog(true), []);
  const handleCloseSelectApproverDialog = useCallback(() => setIsOpenSelectApproverDialog(false), []);

  const handleOpenDeleteRegulationDialog = () => {
    setIsOpenConfirmDialog(true);
    promisesListRef.current.add(handleDeleteRegulation);
  };

  const handleCancelConfirm = async () => {
    setIsOpenConfirmDialog(false);
    promisesListRef.current.reset();
  };

  const handleSuccessConfirm = async () => {
    setIsOpenConfirmDialog(false);
    await promisesListRef.current.run();
  };

  const handlePasteContentType = () => `contentType=${regulationContentType}`;

  const handleApiError = () => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });

  const handleSetHasDraftTestChanges = (value: boolean) => setHasDraftTestChanges(value);

  const handleCloseTestPassDialog = () => {
    // navigate(`/policy/all/${regulation?.id}/?${handlePasteContentType()}`);
    setIsOpenTestPassDialog(false);
  };
  const handleCloseAndReloadTestPassDialog = async () => {
    setIsOpenTestPassDialog(false);
    setIsForceReload(true);
    // navigate(`/policy/all/${regulation?.id}`);
    // await handleLoadRegulation(2);
  };

  const handleOpenTestPassDialog = () => {
    setIsOpenTestPassDialog(true);
    // navigate(`/policy/all/${regulation?.id}/study/`, {
    //   // navigate(`/policy/all/${regulation?.id}/study/?${handlePasteContentType()}`, {
    //   // @ts-ignore
    //   state: { ...location?.state },
    // });
  };

  const handleCancelEditable = async () => {
    // await db.del("draft-editor" + regulation?.id);
    localStorage.removeItem("regulation-draft-editor" + regulation?.id);
    handleChangeEditable(false);
    setHasDraftChanges(false);
    setHasDraftTestChanges(false);
    // db.content.delete("regulation-history-editor" + regulation?.id);
  };

  const handleChangeDraftContent = (newContent: string, withoutUpdateChangesFlag?: boolean) => {
    setDraftContent((prev) => ({ ...prev, content: newContent }));
    if (newContent === (regulation?.draftContent?.content ?? "")) return setHasDraftChanges(false);
    !hasDraftChanges && !withoutUpdateChangesFlag && setHasDraftChanges(true);
  };

  const handleCatchOpenPassTestDialog = (
    thisTestIsPassed: boolean | undefined,
    thisCountQuestions: number | undefined,
    regulationId: number
  ): boolean => {
    if (thisTestIsPassed ?? testIsPassed) {
      notifier.show({ message: t("notifier:error.test_is_passed"), theme: "error" });
      navigate(`/policy/all/${regulationId}/?${handlePasteContentType()}`);
      return false;
    }
    if (!(thisCountQuestions ?? countQuestions)) {
      notifier.show({ message: t("notifier:error.regulation_no_test"), theme: "error" });
      navigate(`/policy/all/${regulationId}/?${handlePasteContentType()}`);
      return false;
    }
    return true;
    if (testIsPassed) {
      notifier.show({ message: t("notifier:error.test_is_passed"), theme: "error" });
      navigate(`/policy/all/${regulationId}/${handlePasteContentType()}`);
      return false;
    }
  };

  // const handleRedirectToPassTest = (regulationId ?: number) => {
  //   setIsOpenTestPassDialog(true);
  //   navigate(
  //     `/policy/all/${regulationId ?? regulation?.id}/study/?${handlePasteContentType()}`,
  //     // @ts-ignore
  //     { state: { ...location.state } }
  //   );
  // };

  const handleChangeEditable = (newValue: boolean) => {
    setIsEditable(newValue);
    if (!newValue) {
      setDraftContent(regulation?.draftContent ?? currentContent);
      setTitle(regulation?.name ?? "");
    }
  };
  const handleChangeTitle = (newValue: string, withoutUpdateChangesFlag?: boolean) => {
    setTitle(newValue);
    !hasDraftChanges && !withoutUpdateChangesFlag && setHasDraftChanges(true);
  };

  const validateErrorHandler = (errorMessage: string) => {
    setIsLoading(false);
    switch (errorMessage) {
      case errorKeys.emptyTitle:
        notifier.show({ message: t("validation:enter_regulation_name"), theme: "error" });
        break;
      case errorKeys.noAnswers:
        notifier.show({ message: t("validation:no_answers"), theme: "error" });
        break;
      case errorKeys.noRightsAnswer:
        notifier.show({ message: t("validation:no_rights_answer"), theme: "error" });
        break;
      case errorKeys.noNameAnswer:
        notifier.show({ message: t("validation:no_name_answer"), theme: "error" });
        break;
      case errorKeys.noNameQuestion:
        notifier.show({ message: t("validation:no_name_question"), theme: "error" });
        break;
    }
  };
  
  const handleReloadNavigationItem = async () => {
    setIsForceReload(false);
    await fetchNavigationMenuItem();
  }

  const handleSaveDraftChanges = async () => {
    if (hasDraftTestChanges) {
      try {
        validateTest(testRef.current.test);
      } catch (error: any) {
        validateErrorHandler(error.message as string);
        return;
      }
    }
    if (hasDraftChanges || hasDraftTestChanges) {
      setIsLoadingSaveChangesBtn(true);
      await api.regulation
        .edit(regulation!.id!, {
          ...regulation,
          name: title,
          draftContent: {
            ...regulation?.draftContent,
            content: draftContent!.content,
            questions: resetIds(testRef.current.test),
          },
        })
        .then(async (res) => {
          if (res) {
            await handleReloadNavigationItem();
            notifier.show({ message: t("notifier:success.draft_was_saved"), theme: "success" });
            setIsEditable(false);
            setDraftContent(res.draftContent);
            localStorage.removeItem("regulation-draft-editor" + regulation?.id);
            testRef.current = { test: res.draftContent?.questions ?? [], hasChanges: false };
            globalAuthorizedContext?.regulation?.reloadSidebarData?.(res.id, true);
          }
        })
        .catch(handleApiError)
        .finally(() => {
          // globalAuthorizedContext?.regulation?.reloadSidebarData?.();
          setIsLoadingSaveChangesBtn(false);
        });
    }
  };

  const handleAddRegulationToSection = (section: AutocompleteModel | undefined) => {
    if (section?.id) {
      setIsLoadingAddToSectionBtn(true);
      api.regulation
        .addToSection(section.id, regulation!.id!)
        .then(() => {
          globalAuthorizedContext?.regulation?.reloadSidebarData?.(regulation!.id, true);
          notifier.show({ message: t("notifier:success.regulation_add_to_section"), theme: "success" })
        })
        .catch(handleApiError)
        .finally(() => {
          //section.id
          // globalAuthorizedContext?.regulation?.reloadSidebarData?.();
          setIsLoadingAddToSectionBtn(false);
        });
    }
  };

  const handleEditTest = () => {
    setIsLoadingSubmitApproval(true);
    api.regulation
      .edit(regulation?.id!, {
        ...regulation,
        draftContent: {
          ...regulation?.draftContent,
          questions: testRef.current.test ?? [],
        },
      })
      .then((res) => {
        setIsEditable(false);
        // res && setRegulation(res);
        notifier.show({ message: t("notifier:success.test_successful_edited"), theme: "success" });
        res && setRegulation(res);
      })
      .catch(handleApiError)
      .finally(() => setIsLoadingSubmitApproval(false));
  };

  //TODO: DS check this moment
  const handleSubmittingForApproval = async () => {
    await api.regulation
      .publish(regulation?.id!)
      .then(() => notifier.show({ message: t("notifier:success.submitted_for_approval"), theme: "success" }))
      .catch(handleApiError);
  };

  const handleAppointApprover = async (approverId: number, resetTestResults: boolean, urgency?: string) => {
    await api.regulation
      .edit(regulation?.id!, {
        ...regulation,
        ...(urgency && { timeToStudy: urgency }),
        draftContent: {
          ...regulation?.draftContent,
          userPublisherId: approverId,
          resetTestResultsOnPublish: resetTestResults,
        },
      })

      .then(() => {
        globalAuthorizedContext?.regulation?.reloadSidebarData?.(regulation?.id, true);
        notifier.show({ message: t("notifier:success.submitted_for_approval"), theme: "success" });
        setIsForceReload(true);
      })
      .finally(() => {
      })
      .catch(handleApiError);
  };

  const handleRequestPublication = async (
    approverId: number | null,
    resetTestResults?: boolean,
    isPublishWithoutApproval?: boolean,
    urgency?: string
  ) => {
    handleCloseSelectApproverDialog();
    setIsLoadingSubmitApproval(true);
    if (isPublishWithoutApproval) {
      await handlePublish(resetTestResults);
    } else {
      await handleAppointApprover(approverId!, resetTestResults ?? false, urgency);
    }
    setIsForceReload(true);
    setIsLoadingSubmitApproval(false);
  };

  const handlePublish = useCallback(async (resetTestResultsOnPublish?: boolean) => {
    setIsLoadingApproveBtn(true);
    api.regulation
      .publish(regulation?.id!, true, resetTestResultsOnPublish)
      .then((res) => {
        // ?contentType=${regulationContentTypeKeys.approved}
        if (res !== null) {
          // navigate(`/policy/all/${regulation!.id}/`);
          notifier.show({ message: t("notifier:success.regulation_is_approved"), theme: "success" });
          isEditable && setIsEditable(false);
          globalAuthorizedContext?.regulation?.reloadSidebarData?.(regulation!.id, true);
          setIsForceReload(true);
        } else {
          handleApiError();
        }
      })
      .catch(handleApiError)
      .finally(() => {
        setIsLoadingApproveBtn(false);
      });
  }, [regulation]);

  const handleReject = () => {
    setIsLoadingRejectBtn(true);
    api.regulation
      .reject(regulation?.id!)
      .then(() => {
        globalAuthorizedContext?.regulation?.reloadSidebarData?.(regulation!.id, true);
        navigate(`/policy/all/${regulation!.id}/`);
        notifier.show({ message: t("notifier:success.regulation_is_rejected"), theme: "success" });
        setIsForceReload(true);
      })
      .catch(handleApiError)
      .finally(() => {
        setIsLoadingRejectBtn(false);
      });
  };

  const handleDeleteRegulation = async () => {
    setIsLoadingDeleteBtn(true);
    api.regulation
      .del(regulation?.id!)
      .then(() => {
        localStorage.removeItem("regulation-draft-editor" + regulation?.id);
        localStorage.removeItem("regulation-history-editor" + regulation?.id);
        navigate(`/policy`);
        notifier.show({ message: t("notifier:success.delete_regulation"), theme: "success" });
        globalAuthorizedContext?.regulation?.reloadSidebarData?.(navigationMenuItem?.parentId);
      })
      .catch(handleApiError)
      .finally(() => {
        setIsLoadingDeleteBtn(false);
      });
  };

  const handleInitialization = (resRegulation: RegulationDto) => {
    handleChangeTitle(resRegulation.name!, true);
    breadcrumbsStore.setLastCrumb(resRegulation.name ?? "");
    setRegulation(resRegulation);
    switch (regulationContentType) {
      case regulationContentTypeKeys.draft: {
        setCurrentContent(resRegulation?.draftContent);
        setDraftContent(resRegulation?.draftContent);
        setEditableTest(resRegulation?.draftContent?.questions ?? []);
        break;
      }
      case regulationContentTypeKeys.approved: {
        setCurrentContent(resRegulation?.activeContent);
        setDraftContent(resRegulation?.draftContent ?? resRegulation?.activeContent);
        setEditableTest(
          (resRegulation?.draftContent?.questions
            ? resRegulation?.draftContent?.questions
            : resRegulation?.activeContent?.questions) ?? []
        );
        break;
      }
      case regulationContentTypeKeys.unknown: {
        setCurrentContent(resRegulation?.activeContent ?? resRegulation?.draftContent);
        setDraftContent(resRegulation?.draftContent ?? resRegulation?.activeContent);
        setEditableTest(resRegulation?.draftContent?.questions ?? resRegulation?.activeContent?.questions ?? []);
        break;
      }
      case null: {
        setCurrentContent(resRegulation?.activeContent ?? resRegulation?.draftContent);
        setDraftContent(resRegulation?.draftContent ?? resRegulation?.activeContent);
        setEditableTest(resRegulation?.draftContent?.questions ?? resRegulation?.activeContent?.questions ?? []);
      }
    }
  };

  const handleFetchRegulation = async (id: number) => {
    setIsLoading(true);
    const awaitedRegulation = await api.regulation
      .getById(id)
      .then((res) => {
        if (res != null) {
          const { breadcrumbs = [] } = res;
          handleInitialization({
            ...res,
            withoutATopic: !breadcrumbs.length,
          });
        } else {
          // handleApiError();
          // navigate("/policy");
        }
        return res;
      })
      .catch((error) => {
        handleApiError();
        navigate("/policy");
      })
      .finally(() => {
        setIsLoading(false);
      });
    return awaitedRegulation;
  };

  useEffect(() => {
    if (navigationMenuItem && navigationMenuItem.regulation) {
      handleInitialization(navigationMenuItem.regulation);
    }
  }, [navigationMenuItem]);

  const handleLoadRegulation = async (byForceId?: number) => {
    setIsForceReload(false);
    if (byForceId) {
      const id = findId(pathname, byForceId);
      await handleFetchRegulation(id!);
      // setIsForceReload(false);
      return;
    }
    const id = findId(pathname, props.isTestPass ? 2 : 1);
    if (id && !isForceReload) await handleFetchRegulation(id);
    else navigate("/policy");
  };

  const handleStudyRegulation = () => {
    setIsLoadingStudyBtn(true);
    api.regulation
      .study(regulation?.id!)
      .then(() => {
        // setIsForceReload(true);
        fetchUsers(true);
        notifier.show({ message: t("notifier:success.policy_studied_successfully"), theme: "success" });
      })
      .catch(handleApiError)
      .finally(() => {
        setIsLoadingStudyBtn(false);
      });
  };

  const handleSaveAccessSettings = async (rules: RoleSelectorDto[]): Promise<boolean> => {
    // if (regulation) {
    //   const data = rules.map((item) => ({
    //     roleId: item.roleId,
    //     roleName: item.role?.name,
    //     isSelected: item.isSelected ?? true,
    //     includeNestedRoles: item.includeNestedRoles ?? false,
    //   }
    //   ));
    //   console.log("data1", data);
      const status = await api.regulation.sendForStudy(regulation?.id!, rules);
      if (status === null) {
        handleApiError();
        return false;
      }
      handleCloseAccessSettingsDialog();
      return true;
      // .then((res) => {
        //   // if (res) {
        //   //   handleCloseAccessSettingsDialog();
        //   //   setRegulation(res);
        //   //   handleCloseAccessSettingsDialog();
        //   // }
        // })
        // .catch(() => {
        //   handleApiError();
        //   return false;
        // });
    //   return true;
    // } else {
    //   notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
    //   return false;
    // }
  };

  // load regulation for instantly open test dialog witch regulation questions
  useEffect(() => {
    const loadTestWithRegulationId = async () => {
      const id = findId(pathname, 2);
      const currentRegulation = regulation ?? (await handleFetchRegulation(id!));
      if (currentRegulation) {
        const testIsPassed: boolean = !!currentRegulation?.isStudied;
        const countQuestions: number = handleCountQuestions(
          currentRegulation?.activeContent ?? currentRegulation.draftContent ?? undefined
        );
        const permissionToPassTest = handleCatchOpenPassTestDialog(testIsPassed, countQuestions, currentRegulation.id!);
        permissionToPassTest && setIsOpenTestPassDialog(true);
      }
    };
    if (props.isTestPass) loadTestWithRegulationId();
  }, [props.isTestPass]);

  // useEffect(() => {
  //   handleLoadRegulation();
  //   return () => breadcrumbsStore.resetLastCrumb();
  // }, [testIsPassed]);

  // useEffect(() => {
  //   const currentRegulationId = findId(pathname, 1);
  //   if (currentRegulationId && ((regulation && regulation.id !== currentRegulationId) || regulation == null)) {
  //     setIsForceReload(true);
  //   }
  // }, [pathname]);

  const isCreator = useMemo(() => {
    return regulation?.createdByUserId === authStore.getInitialInfo?.identity?.id;
  }, [authStore.getInitialInfo?.identity?.id, regulation?.createdByUserId]);

  const isPublisher = useMemo(() => {
    return regulation?.draftContent?.userPublisherId === authStore.getInitialInfo?.identity?.id;
  }, [authStore.getInitialInfo?.identity?.id, regulation?.draftContent?.userPublisherId]);

  const isShowPublishWithoutApprovalCheckbox = useMemo(() => {
    return defineAllow(allowsRegulationKeys.publish, regulation?.actions)
  }, [regulation?.actions]);

  const handleAddedRegular = useCallback(async (_: number[], sections: number[]) => {
    const [sectionId] = sections;
    const status = await api.navigationMenu
      .addRegulationsToSection(
        sectionId,
        {
          regulationIds: [regulation?.id!],
        });
    if (status !== null) {
      globalAuthorizedContext?.regulation?.reloadSidebarData?.(sectionId);
    }
    return status;
  }, [regulation?.id]);

  const sectionsList = useMemo(() => {
    const { breadcrumbs = [] } = regulation ?? {};
    const list = breadcrumbs.map((item): ISelectItem => {
      const arr: string[] = [];
      const breadcrumbs = [...item];
      const el = breadcrumbs.pop();
      breadcrumbs.forEach((breadcrumb) => {
        arr.push(breadcrumb.name);
      });
      return {
        value: el?.id ?? -1,
        text: arr.join(" - "),
      };
    });
    return list;
  }, [regulation?.breadcrumbs]);

  const navigationMenuItemId = useMemo(() => {
    const [firstNavigationMenuItem] = sectionsList ?? [];
    return firstNavigationMenuItem ? +firstNavigationMenuItem?.value : navigationMenuItem?.id;
  }, [navigationMenuItem, sectionsList]);


  const usersWhoStudied = usePagingWithController(
    api.regulation,
    {
      isStudied: true,
    },
    { pageSize: 15 },
    undefined,
    handleApiError,
    undefined,
    undefined,
    `${regulation?.id}/users`,
    //handlerSuccessRequestEnd
  );

  const usersWhoStudiedList = useMemo(() => {
    return usersWhoStudied.items ?? [];
  }, [usersWhoStudied.items]);

  const usersWhoStudiedLoadNext = useCallback(() => {
    return usersWhoStudied.loadNext()
  }, [regulation?.id]);

  const usersToStudy = usePagingWithController(
    api.regulation,
    {
      isStudied: false,
    },
    { pageSize: 15 },
    undefined,
    handleApiError,
    undefined,
    undefined,
    `${regulation?.id}/users`,
    //handlerSuccessRequestEnd
  );

  const usersToStudyList = useMemo(() => {
    return usersToStudy.items ?? [];
  }, [usersToStudy.items]);

  const usersToStudyLoadNext = useCallback(() => {
    return usersToStudy.loadNext()
  }, [regulation?.id]);

  const fetchUsers = useCallback((withoutStateReset?: boolean) => {

    if (regulation?.id) {
      usersWhoStudied.restart(withoutStateReset);
      usersToStudy.restart(withoutStateReset);
    }
  }, [regulation?.id]);

  useEffect(() => {
    fetchUsers();
  }, [regulation?.id]);

  useEffect(() => {
    if (isForceReload) {
      handleReloadNavigationItem();
      fetchUsers(true);
    }
  }, [isForceReload]);

  return (
    <>
      {isOpenAssignForStudyDialog && (
        <SettingUpAccessDialog
          keyRuleTo={keysRulesFor.regulation}
          // onSave={handleSaveAccessSettings}
          isOpen={isOpenAssignForStudyDialog}
          currentRules={(regulation?.userSelectionRules as UserSelectionRule[]) ?? []}
          onClose={handleCloseAssignForStudyDialog}
          regulationId={regulation?.id!}
          title={regulation?.name!}
        />
      )}
      {isOpenAccessSettingsDialog && (
        <RegulationAccessControlDialog
          isOpen={isOpenAccessSettingsDialog}
          onClose={handleCloseAccessSettingsDialog}
          sectionId={navigationMenuItemId}
          title={regulation?.name!}
          createdByUser={regulation?.createdByUser}
          sectionsList={sectionsList}
        />
      )}
      {isOpenTestPassDialog && (
        <PassingRegulationTestDialog
          open={isOpenTestPassDialog}
          onCloseWitchReload={handleCloseAndReloadTestPassDialog}
          onClose={handleCloseTestPassDialog}
          regulationId={regulation?.id!}
          questions={regulation?.activeContent?.questions ?? []}
        />
      )}
      {isOpenFindSectionDialog && (
        <AddingRegulationsToSectionDialog
          onAddedRegular={handleAddedRegular}
          onClose={handleCloseFindSectionDialog}
          isSingleChoice
        />
        // <FindRegulationSectionDialog
        //   currentRegulationId={regulation?.id}
        //   open={isOpenFindSectionDialog}
        //   onSaveSection={handleAddRegulationToSection}
        //   onClose={handleCloseFindSectionDialog}
        // />
      )}
      {isOpenSelectApproverDialog && (
        <SelectApproverDialog
          withCheckbox={!!regulation?.activeContent}
          // withUrgency
          isShowPublishWithoutApprovalCheckbox={isShowPublishWithoutApprovalCheckbox}
          open={isOpenSelectApproverDialog}
          onClose={handleCloseSelectApproverDialog}
          onSuccess={handleRequestPublication}
        />
      )}

      {isOpenConfirmDialog && (
        <CustomConfirmDialog
          open={isOpenConfirmDialog}
          onConfirm={handleSuccessConfirm}
          title={t("ui:confirm_dialog_remove_from_section.title")}
          subTitle={t("ui:text.delete_regulation")}
          onClose={handleCancelConfirm}
        />
      )}

      <PageHeader isOverflow>
        <RegulationViewHeader
          onSetHasDraftTestChanges={handleSetHasDraftTestChanges}
          isLoadingDeleteBtn={isLoadingDeleteBtn}
          onOpenDeleteRegulationDialog={handleOpenDeleteRegulationDialog}
          isEditable={isEditable}
          isDraft={!!regulation?.draftContent}
          isCreator={isCreator}
          isPublisher={isPublisher}
          isUnderApproval={!!regulation?.draftContent?.userPublisherId}
          hasActiveContent={!!regulation?.activeContent}
          isLoadingSaveChangesBtn={isLoadingSaveChangesBtn}
          hasDraftChanges={hasDraftChanges || hasDraftTestChanges}
          allowedActions={[...(regulation?.actions ?? []), ...(navigationMenuItem?.actions ?? [])]}
          status={regulation?.state}
          isLoadingRejectBtn={isLoadingRejectBtn}
          isLoadingApproveBtn={isLoadingApproveBtn}
          isLoadingSubmitApproval={isLoadingSubmitApproval}
          isLoadingAddToSectionBtn={isLoadingAddToSectionBtn}
          onCancelEditable={handleCancelEditable}
          onSaveDraftChanges={handleSaveDraftChanges}
          onOpenAssignForStudyDialog={handleOpenAssignForStudyDialog}
          onOpenAccessSettingsDialog={handleOpenAccessSettingsDialog}
          onOpenSelectApproverDialog={handleOpenSelectApproverDialog}
          onOpenFindSectionDialog={handleOpenFindSectionDialog}
          onChangeEditable={handleChangeEditable}
          onPublish={handlePublish}
          onReject={handleReject}
          breadcrumbsList={breadcrumbsList}
          regulationId={regulation?.id}
          withoutATopic={regulation?.withoutATopic}
        />
      </PageHeader>
      <PageContent>
        {!isLoading && regulation == null ? (
          <Card>
            <Empty />
          </Card>
        ) : (
          <RegulationContent
            isLoading={isLoading}
            isTestPass={props.isTestPass}
            isEditable={isEditable}
            isLoadingStudyBtn={isLoadingStudyBtn}
            draftTitle={title}
            currentContent={currentContent}
            editableTest={editableTest}
            testIsPassed={testIsPassed}
            isDraft={isDraft}
            countQuestions={countQuestions}
            testRef={testRef}
            regulation={regulation}
            usersWhoStudied={usersWhoStudiedList}
            usersWhoStudiedTotalItems={usersWhoStudied.info.totalItems ?? 0}
            usersWhoStudiedIsDone={usersWhoStudied.info.isDone}
            usersToStudy={usersToStudyList}
            usersToStudyTotalItems={usersToStudy.info.totalItems ?? 0}
            usersToStudyIsDone={usersToStudy.info.isDone}
            usersWhoStudiedLoadNext={usersWhoStudiedLoadNext}
            usersToStudyLoadNext={usersToStudyLoadNext}
            allowedActions={regulation?.actions}
            onChangeDraftContent={handleChangeDraftContent}
            onSetHasDraftTestChanges={handleSetHasDraftTestChanges}
            onChangeTitle={handleChangeTitle}
            draftContent={draftContent}
            onStudyRegulation={handleStudyRegulation}
            onOpenTestPassDialog={handleOpenTestPassDialog}
            onOpenSelectApproverDialog={handleOpenSelectApproverDialog}
          />
        )}
      </PageContent>
    </>
  );
});
