import { useTranslation } from "react-i18next";
import { useRootStore } from "../../../../../../hooks";
import { Collapse, CollapsePanel, Tooltip } from "../../../../../uiKit";
import { groupingByRoleId, groupingByType } from "../../helpers/groupingArrayByProperty";
import { IssuesCalendarDialog } from "../issuesCalendarDialog/IssuesCalendarDialog";
import { ICustomIssue, IPlanningCollapseContentView } from "./PlanningCollapseContent.interface";
import "./PlanningCollapseContent.scss";
import { PlanningCollapseContentTable } from "./planningCollapseContentTable/PlanningCollapseContentTable";
import { memo, useCallback, useEffect, useMemo } from "react";

export const PlanningCollapseContentView = memo((props: IPlanningCollapseContentView) => {
  const { orgchartStore } = useRootStore();
  const getOrgchartById = (id: number) => orgchartStore.getOrgchartsList.find((o) => o.id == id);
  const { t } = useTranslation();

  const typeList = useCallback((type) => {
    return groupingByType(type) ?? [];
  }, []);

  const groupingByRoleList = useMemo(() => {
    return groupingByRoleId(props.issuesByCustomType) ?? []
  }, [props.issuesByCustomType]);


  return (
    <>
      <IssuesCalendarDialog
        open={props.isOpen}
        onClose={() => props.isOpenChange(false)}
        onTaskCreateClick={props.onIssueToPlanCreate}
        calendarProps={{ initialDate: props.planDateFrom }}
        weekStartDay={props.weekReportStart}
        executorUserId={props.userId}
      />
      <div className="planning-collapse">
        {groupingByRoleList.map((i: ICustomIssue[]) => (
          <Collapse
            expandIconPosition="end"
            key={props.onIncrementGroupId()
          }>
            <CollapsePanel
              key={props.onIncrementGroupId()}
              header={
                <div className="d-stack-row align-center justify-start">
                  <Tooltip title={(i[0]?.orgchartId && getOrgchartById(i[0].orgchartId)?.name) ?? ""}>
                    <div
                      className="mr-1"
                      style={{
                        width: "15px",
                        height: "15px",
                        borderRadius: "50%",
                        visibility: i.find((item: ICustomIssue) => item?.role?.name)?.role?.name ? "visible" : "hidden",
                        background:
                          (i[0]?.orgchartId &&
                          getOrgchartById(i[0].orgchartId)?.colorHex != undefined &&
                          (getOrgchartById(i[0].orgchartId)?.colorHex ?? "").trim().length > 0
                            ? i[0]?.orgchartId && getOrgchartById(i[0].orgchartId)?.colorHex
                            : "var(--color-layout-fill-base)") ?? "var(--color-layout-fill-base)",
                      }}
                    />
                  </Tooltip>
                  {i.find((item: ICustomIssue) => item?.role?.name)?.role?.name ?? t("common:planning.no_function")}
                </div>
              }
              className="collapse-panel mb-6"
            >
              <div style={{ overflowX: "auto" }}>
                {typeList(i).map((item: ICustomIssue[]) => {
                  return (
                    <PlanningCollapseContentTable
                      key={props.onIncrementGroupId()}
                      title={props.issueGroupTitle(item?.find((i) => i.type)?.type ?? undefined) ?? ""}
                      issues={item}
                      onIssueDelete={props.onIssueDelete}
                      loadingTaskId={props.loadingTaskId}
                      onIssueEdit={props.onIssueEdit}
                      onLoadRestart={props.onLoadRestart}
                      isOpenChange={props.isOpenChange}
                      planStatus={props.planStatus}
                      // issuesRestart={props.issuesRestart}
                    />
                  );
                })}
              </div>
            </CollapsePanel>
          </Collapse>
        ))}
      </div>
    </>
  );
});
