import React, { useCallback, useEffect, useMemo } from "react";
import "./PlanningCollapseContentTable.scss";
import { PlanningCollapseContentTableView } from "./PlanningCollapseContentTableView";
import { DatePicker, IconButton, TimeSpanPicker, Tooltip } from "../../../../../../uiKit";
import { AiOutlineCalendar } from "@react-icons/all-files/ai/AiOutlineCalendar";
import { ColumnsType } from "antd/es/table";
import { css } from "@emotion/css";
import { FiX } from "@react-icons/all-files/fi/FiX";
import { IPlanningCollapseContentTable } from "./PlanningCollapseContentTable.interface";
import { useTranslation } from "react-i18next";
import { IssueSwitchStatus } from "../../../../../../elements/issueSwitchStatus/IssueSwitchStatus";
import { getObjectFromTimeSpan, timeSpanOperation } from "../../../../../../../helpers/dateFunctions";
import { IssueQueryKeys } from "../../../../../../../constants/issueTypeKeys";
import { useNavigate } from "react-router-dom";
import { ICustomIssue } from "../PlanningCollapseContent.interface";
import dayjs from "dayjs";
import { IoExitOutline } from "@react-icons/all-files/io5/IoExitOutline";
import { removeLeadingZerosFromString } from "../../../../../../../helpers/stringFunctions";
import { useRootStore } from "../../../../../../../hooks";

export const PlanningCollapseContentTable = (props: IPlanningCollapseContentTable) => {
  useEffect(() => {
    console.log("PlanningCollapseContentTable");
  });
  const { issueInitDataStore, appStore } = useRootStore();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentLocale = appStore.getAppLocale;

  const taskResultStyle = css({
    color: "#8e939d",
    fontSize: "12px",
    marginTop: "4px",
  });

  const handleOpenIssue = useCallback((issue: any) => {
    navigate({
      search: `?${IssueQueryKeys.issueId}=${issue.id}`,
    });
    issueInitDataStore.setOnStatusChange = props.onLoadRestart;
    issueInitDataStore.setOnChangeDefiniteField = props.onLoadRestart;
  }, []);

  const timePlansArr = props.issues
    ?.filter((i) => !i.isDeleted && (i.issue.timePlan ?? "").trim().length > 0)
    .map((i) => i.issue.timePlan);
  const timeFactsArr = props.issues
    ?.filter((i) => !i.isDeleted && (i.issue.timeFact ?? "").trim().length > 0)
    .map((i) => i.issue.timeFact);
  const timePlanResult = timeSpanOperation().add(timePlansArr as string[]);
  const timeFactResult = timeSpanOperation().add(timeFactsArr as string[]);

  const getTimeWithHoursAndMinutes = (time: string) => {
    const hours = removeLeadingZerosFromString(getObjectFromTimeSpan(time).totalHours ?? 0);
    const minutes = removeLeadingZerosFromString(getObjectFromTimeSpan(time).minutes ?? 0);
    return { hours, minutes };
  };

  const columns: ColumnsType<ICustomIssue> = useMemo(() => ([
    {
      title: props.title,
      dataIndex: "issues",
      className: "issue-info",
      render: (_, record) => {
        return (
          <div className="d-stack-column" style={{ wordBreak: "break-word" }}>
            <div
              style={{ cursor: !record.isSchedule ? "pointer" : "default" }}
              onClick={() => !record.isSchedule && handleOpenIssue(record.issue)}
            >
              {record.issue.name}
            </div>
            <div className={taskResultStyle}>{record.issue?.awaitedResult}</div>
          </div>
        );
      },
    },
    {
      title: `${t("ui:planning_table.plan")} \n (${getTimeWithHoursAndMinutes(timePlanResult).hours}${t(
        "common:planning.hour"
      ).trim()} ${getTimeWithHoursAndMinutes(timePlanResult).minutes}${t("common:planning.minute").trim()})`,
      dataIndex: "plan",
      className: "issue-plan-fact",
      // width: 150,
      render: (_, record) => {
        const isBlocked = record.issue?.calculated?.blockedFields?.find((i: string) => i == "TimePlan");
        return (
          // <TimePicker
          //   disabled={record.isDeleted || props.planStatus == 2 || record.isSchedule || isBlocked}
          //   size="middle"
          //   allowClear={false}
          //   value={getObjectFromTimeSpan(record.issue.timePlan ?? "00:00:00").dayjsObject}
          //   onChange={(date) => {
          //     props.onIssueEdit(record.issue.id ?? null, {
          //       ...record.issue,
          //       timePlan: generateTimeSpan({ dateObject: date?.toDate() ?? undefined }),
          //     });
          //   }}
          // />
          <>
          <TimeSpanPicker
            disabled={record.isDeleted || props.planStatus == 2 || record.isSchedule || isBlocked}
            size="middle"
            allowClear={false}
            value={record.issue.timePlan}
            onChange={(date, ...arg) => {
              props.onIssueEdit(record.issue.id ?? null, {
                ...record.issue,
                timePlan: date,
              }, {
                path: "timePlan",
                value: date,
              });
            }}
          />
          </>
        );
      },
      align: "center",
    },
    {
      title: `${t("ui:planning_table.fact")} \n (${getTimeWithHoursAndMinutes(timeFactResult).hours}${t(
        "common:planning.hour"
      ).trim()} ${getTimeWithHoursAndMinutes(timeFactResult).minutes}${t("common:planning.minute").trim()})`,
      dataIndex: "fact",
      align: "center",
      className: "issue-plan-fact",
      // width: 150,
      render: (_, record) => {
        const isBlocked = record.issue?.calculated?.blockedFields?.find((i: string) => i == "TimeFact");
        return props.planStatus == 3 ? (
          // <TimePicker
          //   disabled={record.isDeleted || record.isSchedule || isBlocked}
          //   size="middle"
          //   allowClear={false}
          //   value={getObjectFromTimeSpan(record.issue.timeFact ?? "00:00:00").dayjsObject}
          //   onChange={(date) =>
          //     props.onIssueEdit(record.issue.id ?? null, {
          //       ...record.issue,
          //       timeFact: generateTimeSpan({ dateObject: date?.toDate() ?? undefined }),
          //     })
          //   }
          // />
          <TimeSpanPicker
            disabled={record.isDeleted || record.isSchedule || isBlocked}
            size="middle"
            allowClear={false}
            value={record.issue.timeFact}
            onChange={(date) => {
              props.onIssueEdit(record.issue.id ?? null, {
                ...record.issue,
                timeFact: date,
              }, {
                path: "timeFact",
                value: date,
              });
            }}
          />
        ) : (
          `${getTimeWithHoursAndMinutes(record.issue.timeFact ?? "00:00:00").hours}${t(
            "common:planning.hour"
          ).trim()} ${getTimeWithHoursAndMinutes(record.issue.timeFact ?? "00:00:00").minutes}${t(
            "common:planning.minute"
          ).trim()}`
        );
      },
    },
    {
      title: t("ui:planning_table.start"),
      dataIndex: "start",
      className: "issue-start-end",
      render: (_, record) => {
        const isBlocked = record.issue?.calculated?.blockedFields?.find((i: string) => i == "DateWorkStart");
        return (
          <DatePicker
            value={record.issue.dateWorkStart ? dayjs(record.issue.dateWorkStart as string) : null}
            onChange={(date) =>
              props.onIssueEdit(record.issue.id ?? null, {
                ...record.issue,
                dateWorkStart: date?.toISOString()
              }, {
                path: "dateWorkStart",
                value: date?.toISOString(),
              })
            }
            showTime={{ format: "HH:mm" }}
            disablePast
            placeholder={t("ui:planning_table.start")}
            size="middle"
            disabled={record.isDeleted || props.planStatus == 2 || record.isSchedule || isBlocked}
          />
        );
      },
      align: "center",
    },
    {
      title: t("ui:planning_table.deadline"),
      dataIndex: "end",
      className: "issue-start-end",
      render: (_, record) => {
        const isBlocked = record.issue?.calculated?.blockedFields?.find((i: string) => i == "DateDeadline");
        return (
          <DatePicker
            value={record.dateDeadline ? dayjs(record.dateDeadline as string) : null}
            onChange={(date) =>
              props.onIssueEdit(record.issue.id!, {
                ...record.issue,
                dateDeadline: date?.toISOString()
              }, {
                path: "dateDeadline",
                value: date?.toISOString(),
              })
            }
            showTime={{ format: "HH:mm" }}
            disablePast
            placeholder={t("ui:planning_table.deadline")}
            size="middle"
            disabled={record.isDeleted || props.planStatus == 2 || record.isSchedule || isBlocked}
          />
        );
      },
      align: "center",
    },
    // {
    //   title: "",
    //   dataIndex: "calendar",
    //   className: "issue-plan-fact",
    //   render: (_, record) => {
    //     return (
    //       <Tooltip
    //         title={!record.issue.dateWorkStart && !record.issue.timePlan ? t("text:no_dateStart_and_timePlan") : ""}
    //         overlayStyle={{ minWidth: "fit-content" }}
    //       >
    //         <IconButton
    //           onClick={() => props.isOpenChange(true)}
    //           disabled={
    //             record.isDeleted ||
    //             props.planStatus == 2 ||
    //             record.isSchedule ||
    //             record.issue.dateDeadlineCalculated == null
    //           }
    //           style={{ margin: "0 auto" }}
    //           icon={<AiOutlineCalendar />}
    //         />
    //       </Tooltip>
    //     );
    //   },
    //   align: "center",
    // },
    {
      title: t("ui:planning_table.status"),
      dataIndex: "status",
      className: "status",
      render: (_, record) => {
        const date = record.dateRelease && new Date(record.dateRelease as string);
        return record.isSchedule ? (
          <div className="d-stack-row align-center justify-space-around">
            <IoExitOutline size={19} />
            {dayjs(date).format(currentLocale == "en" ? "MM/DD/YYYY" : "DD/MM/YYYY")}
          </div>
        ) : (
          <IssueSwitchStatus
            isDisabled={record.isDeleted || props.planStatus == 2 || record.isSchedule}
            issueData={record.issue}
            actions={record.issue.calculated.actions}
            onRefreshData={props.onLoadRestart}
          />
        );
      },
      align: "center",
    },
    {
      title: "",
      dataIndex: "delete",
      className: "issue-plan-fact",
      render: (_, record) =>
        props.issues && props.issues.length >= 1 ? (
          <IconButton
            disabled={record.isDeleted || props.planStatus == 2 || record.isSchedule}
            loading={props.loadingTaskId == record.issue.id}
            style={{ margin: "0 auto" }}
            onClick={() => props.onIssueDelete(record.issue.id ?? null)}
            icon={<FiX />}
          />
        ) : null,
      align: "center",
    },
  ]), []);

  return <PlanningCollapseContentTableView columns={columns} dataSource={props.issues ?? []} />;
};