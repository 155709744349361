import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IssueDto, IssueScheduleDto } from "../../../../../../../../api";
import { IssueQueryKeys } from "../../../../../../../../constants/issueTypeKeys";
import { useApi, useNotifier, useRootStore } from "../../../../../../../../hooks";
import { usePagingWithController } from "../../../../../../../../hooks/usePaging";
import { api } from "../../../../../../../../services";
import PageContent from "../../../../../../layouts/pageContent/PageContent";
import { PlanningPageMainContent } from "../../../../contents/planningPageMainContent/PlanningPageMainContent";
import { IPlanningPageAdminWorkPlan } from "./PlanningPageAdminWorkPlan.interface";
import { Breadcrumbs } from "../../../../../../../elements/breadcrumbs/Breadcrumbs";
import { breadcrumbsKeys } from "../../../../../../../elements/breadcrumbs/Breadcrumbs.interface";
import { useTranslation } from "react-i18next";

export const PlanningPageAdminWorkPlan = (props: IPlanningPageAdminWorkPlan) => {
  const navigate = useNavigate();
  const notifier = useNotifier();
  const { t } = useTranslation();

  const { breadcrumbsStore } = useRootStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleOpenCreateIssueDialog = useCallback(() =>
    navigate({ search: `?${IssueQueryKeys.taskCreate}=${true}` })
  ,[]);



  const plan = useApi(
    () => api.plan.getById(props.id, {
      withPlannedIssues: true
    }),
    null,
    () => {},
  );

  const fetchPlan = useCallback(() => {
    return plan.fetch();
  }, [props.id]);

  const approvalPlan = props.plansForApproval.find((p) => p.id == props.id);

  // const issues = usePagingWithController<IssueDto, {}>(
  //   api.issue,
  //   { planId: props.id },
  //   { pageSize: -1 },
  // );

  // const issues = useApi(
  //   () =>
  //     // fetchAssignedIssues
  //     api.plan.fetchIssues(props.id!),
  //   null,
  //   () => {},
  //   {},
  //   false
  // );

  //
  // const issuesSchedule = usePagingWithController<IssueScheduleDto, {}>(
  //   api.issueSchedule,
  //   {
  //     dateReleaseFrom: plan.value?.dateFrom,
  //     dateReleaseTo: plan.value?.dateTo,
  //     executorUserId: plan.value?.userId,
  //   },
  //   { pageSize: -1 }
  // );
  //
  // useEffect(() => {
  //   if (plan.value) {
  //     // issues.fetch();
  //     issuesSchedule.restart();
  //   }
  // }, [plan.value]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 1000);
  // }, []);

  useEffect(() => {
    plan.value?.user?.nameShort && breadcrumbsStore.setLastCrumb(plan.value?.user?.nameShort);
  }, [plan.value?.user?.nameShort]);

  const handleAddIssueToPlan = useCallback(async (issueId: number) => {
    // setLoadingTaskId(issueId);
    const resp = await api.plan.addedIssueToPlan(props.id, issueId);
    if (resp !== null) {
      notifier.show({
        message: t("notifier:success.issue_added"),
        theme: "success",
      });
      // handleLoadRestart();
    } else {
      notifier.show({
        message: t("notifier:error.something_wrong"),
        theme: "error",
      });
    }
    return resp !== null;
  }, [props.id]);

  const issuesRestart = useCallback(() => {
    // return issues.fetch();
    console.log("issuesRestart", issuesRestart);
  }, [props.id]);

  return (
    <PageContent
      isLoading={plan.isLoading}
    >
      {plan.value && (
        <div
          style={{ overflow: "auto" }}
          className="full-height"
        >
          <div className="pl-4 mt-2">
            <Breadcrumbs type={breadcrumbsKeys.planning} />
          </div>
          <PlanningPageMainContent
            onAddIssueToPlan={handleAddIssueToPlan}
            onOpenCreateIssueDialog={handleOpenCreateIssueDialog}
            plan={plan.value ?? undefined}
            issues={plan.value?.plannedIssues ?? []}
            // scheduleIssues={issuesSchedule.items}
            // issuesRestart={issuesRestart}
            fetchPlans={fetchPlan}
            approvalPlan={approvalPlan}
            fetchApprovalPlans={props.fetchApprovalPlans}
            isLoading={plan.isLoading}
            userId={plan.value?.userId ?? undefined}
          />
        </div>
      )}

    </PageContent>
  );
};
