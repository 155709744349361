import { Plan2IssueDto } from "../../../../../api";

export const groupingByRoleId = (issues: any) => {
  const map = issues?.reduce((r: any, i: any) => {
    //@ts-ignore
    r[i.roleId] = r[i.roleId] || [];
    //@ts-ignore
    r[i.roleId].push(i);
    return r;
  }, {});
  const resultArr = [];
  for (const key in map) {
    //@ts-ignore
    resultArr.push(map[key]);
  }
  console.log("resultArr", resultArr);
  return resultArr;
};

export const groupBy = (items: any, key: string) =>
  items.reduce(
    (result: any, item: any) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {}
  );

export const groupingByType = (planToIssue: Plan2IssueDto[] | undefined) => {
  const map = planToIssue?.reduce((r, i) => {
    //@ts-ignore
    r[i.type] = r[i.type] || [];
    //@ts-ignore
    r[i.type].push(i);
    return r;
  }, {});
  const resultArr = [];
  for (const key in map) {
    //@ts-ignore
    resultArr.push(map[key]);
  }
  return resultArr;
};
