import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Plan2IssueDto } from "../../../../../../api";
import { useNotifier } from "../../../../../../hooks";
import { api } from "../../../../../../services";
import { IPlanningPageSubordinatesSidebar } from "./PlanningPageMyPlanSidebar.interface";
import "./PlanningPageMyPlanSidebar.scss";
import { PlanningPageMyPlanSidebarView } from "./PlanningPageMyPlanSidebarView";

export const PlanningPageMyPlanSidebar = memo((props: IPlanningPageSubordinatesSidebar) => {
  const { t } = useTranslation();
  const notifier = useNotifier();
  const [loadingTaskId, setLoadingTaskId] = useState<number | undefined>(undefined);


  const handleAddIssueToPlan = useCallback(async (issueId: number) => {
    setLoadingTaskId(issueId);
    const status = await props.onAddIssueToPlan(issueId);
    setLoadingTaskId(undefined);
    if (status) {
      handleLoadRestart();
    }
    // if (props.planId) {
    //
    //   const resp = await api.plan.addedIssueToPlan(props.planId, issueId);
    //   if (resp !== null) {
    //     notifier.show({
    //       message: t("notifier:success.issue_added"),
    //       theme: "success",
    //     });
    //     handleLoadRestart();
    //   } else {
    //     notifier.show({
    //       message: t("notifier:error.something_wrong"),
    //       theme: "error",
    //     });
    //   }
    //   console.log("resp", resp);
    //   //     ...props.plan,
    //   //     issues: handleGetArray(),
    //   //   });
    // }
  }, [props.planId]);

  // const handleAddIssueToPlan = async (issue: Plan2IssueDto) => {
  //   setLoadingTaskId(issue.issueId);
  //   const handleGetArray = () => {
  //     const currentIssue: Plan2IssueDto | undefined = props.plan?.issues?.find((i) => i.issueId == issue.issueId);
  //     if (currentIssue && currentIssue.isDeleted == true) {
  //       return props.plan?.issues?.map((i) => ({
  //         ...i,
  //         isDeleted: i.issueId == issue.issueId ? false : i.isDeleted,
  //       }));
  //     } else {
  //       return [...(props.plan?.issues ?? []), issue];
  //     }
  //   };
  //   const r = await api.plan.edit(props.plan?.id!, {
  //     ...props.plan,
  //     issues: handleGetArray(),
  //   });
  //   if (r) {
  //     notifier.show({
  //       message: t("notifier:success.issue_added"),
  //       theme: "success",
  //     });
  //     handleLoadRestart();
  //   } else {
  //     notifier.show({
  //       message: t("notifier:error.something_wrong"),
  //       theme: "error",
  //     });
  //   }
  // };

  const handleLoadRestart = () => {
    props.fetchPlans && props.fetchPlans();
    props.issuesFromCommunicationsRestart && props.issuesFromCommunicationsRestart();
    // props.issuesRestart && props.issuesRestart(true);
  };

  useEffect(() => {
    !props.isLoading && setLoadingTaskId(undefined);
  }, [props.isLoading]);

  useEffect(() => {
    console.log("PlanningPageMyPlanSidebar");
  });

  return (
    <PlanningPageMyPlanSidebarView
      // plan={props.plan}
      planId={props.planId}
      issues={props.issues}
      loadingTaskId={loadingTaskId}
      onAddIssue={handleAddIssueToPlan}
      onSidebarOpenChange={props.onSidebarOpenChange}
      isSidebarOpen={props.isSidebarOpen}
      isLoading={props.isLoading}
      issuesFromCommunicationsRestart={props.issuesFromCommunicationsRestart}
      loadNext={props.loadNext}
      isDone={props.isDone}
      filterList={props.filterList}
      filter={props.filter}
      onChangeFilter={props.onChangeFilter}
    />
  );
});
