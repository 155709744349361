import { useTranslation } from "react-i18next";
import { Icon, IconButton, Select, Spin, Text } from "../../../../../uiKit";
import { IPlanningPageSubordinatesSidebarView } from "./PlanningPageMyPlanSidebar.interface";
import "./PlanningPageMyPlanSidebar.scss";
import { FiSidebar } from "@react-icons/all-files/fi/FiSidebar";
import React, { createRef, useCallback, useEffect, useMemo } from "react";
import { CardIssue } from "../../../communications/components/cardIssue/CardIssue";
import ScrollTrigger from "../../../../../service/scrollTrigger/ScrollTrigger";

export const PlanningPageMyPlanSidebarView = (props: IPlanningPageSubordinatesSidebarView) => {
  const { t } = useTranslation();

  const handleAddIssue = useCallback((issueId: number) => {
    props.onAddIssue(issueId);
  }, [props.planId]);

  const planningSidebar = createRef<HTMLDivElement>();

  useEffect(() => {
    if (planningSidebar.current) {
      planningSidebar.current.scrollTop = 0;
    }
  }, [props.filter]);

  return (
    <div className="planning-my-plan-sidebar__wrapper full-height d-stack-column pa-4">
      <div className="d-stack-row align-center justify-space-between mb-4">
        <Select
          className="flex-grow-1"
          onChange={props.onChangeFilter}
          value={props.filter}
          items={props.filterList}
        />
        {/*<Text*/}
        {/*  children={*/}
        {/*    props.issues != null && props.issues.length > 0*/}
        {/*      ? t("ui:subheader.unclosed_tasks")*/}
        {/*      : t("ui:subheader.no_open_tasks")*/}
        {/*  }*/}
        {/*  size="14px"*/}
        {/*  weight="bold"*/}
        {/*/>*/}
        {/*<DropdownMenu*/}
        {/*  items={addPopupItems}*/}
        {/*  iconType="chevron"*/}
        {/*  trigger={["click"]}*/}
        {/*  buttonProps={{*/}
        {/*    type: "primary",*/}
        {/*    text: t("ui:button.add"),*/}
        {/*    className: "full-width",*/}
        {/*  }}*/}
        {/*/>*/}
        <IconButton
          // disabled={!props.metrics.length && !props.availableDashboards.length && !props.myDashboards.length}
          className="flex-shrink-0 ml-4"
          onClick={props.onSidebarOpenChange}
          children={<Icon component={() => <FiSidebar />} />}
        />
      </div>
      <div
        ref={planningSidebar}
        className="planning-my-plan-sidebar__content"
      >
        {props.isDone && props.issues?.length == 0 ? (
          <div className="full-height d-flex align-center justify-center">
            {props.isLoading ? (
              <Spin size="large" spinning />
            ) : (
              <Text size="14px" children={t("text:no_open_tasks.sidebar")} />
            )}
          </div>
        ) : (
          <div>
            {props.issues.map((issue) => (
              <CardIssue
                key={issue.id}
                noDrag
                issue={issue}
                onAddIssue={handleAddIssue}
              />
            ))}
            {/*{!props.isDone && props.issues?.length ? (*/}
            {!props.isDone && props.issues?.length ? (
              <ScrollTrigger
                onIntersection={props.loadNext}
                marginTop={false}
              />
            ) : null}
            {/*<PlanningTaskCards*/}
            {/*  issues={props.issues}*/}
            {/*  onAddIssue={props.onAddIssue}*/}
            {/*  loadingTaskId={props.loadingTaskId}*/}
            {/*  plan={props.plan}*/}
            {/*  issuesFromCommunicationsRestart={props.issuesFromCommunicationsRestart}*/}
            {/*/>*/}
          </div>
        )}
      </div>
    </div>
    // <>
    //   {props.issues && props.issues.length ? (
    //     <div className="sidebar__wrapper px-5 pt-7 full-height">
    //       <div className="d-stack-row align-center justify-space-between">
    //         <Text className="sidebar__title">{t("ui:subheader.unclosed_tasks")}</Text>
    //         <IconButton onClick={props.onSidebarOpenChange} children={<Icon component={() => <FiSidebar />} />} />
    //       </div>
    //       <PlanningTaskCards
    //         issues={props.issues}
    //         onAddIssue={props.onAddIssue}
    //         loadingTaskId={props.loadingTaskId}
    //         plan={props.plan}
    //         issuesFromCommunicationsRestart={props.issuesFromCommunicationsRestart}
    //       />
    //     </div>
    //   ) : (
    //     <div className="sidebar__wrapper pt-8 pr-4 pl-4 full-height">
    //       <div className="d-stack-row align-center justify-space-between" style={{ height: "4%" }}>
    //         <div className="sidebar__title">{t("ui:subheader.no_open_tasks")}</div>
    //         <BsLayoutSidebar
    //           size={22}
    //           onClick={props.onSidebarOpenChange}
    //           className="planning-page-main-content__arrow-button"
    //         />
    //       </div>
    //       <div className="sidebar__text d-stack-row align-center justify-center" style={{ height: "96%" }}>
    //         {t("text:no_open_tasks.sidebar")}
    //       </div>
    //     </div>
    //   )}
    // </>
  );
};
