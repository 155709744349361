import React, { forwardRef, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { getObjectFromTimeSpan } from "../../../../../../helpers/dateFunctions";
import { removeLeadingZerosFromString } from "../../../../../../helpers/stringFunctions";
import { Button, Card, Empty, Icon, Spin, Text, Tooltip } from "../../../../../uiKit";
import { PlanningRejectDialog } from "../../../../dialogs/planningRejectDialog/PlanningRejectDialog";
import { SelectApproverDialog } from "../../../../dialogs/selectApproverDialog/SelectApproverDialog";
import { EmptyBlock } from "../../../../layouts/emptyBlock/EmptyBlock";
import { PlanHistoryModule } from "../../components/planHistoryModule/PlanHistoryModule";
import { PlanningCollapseContent } from "../../components/planningCollapseContent/PlanningCollapseContent";
import { IPlanningPageMainContentView, PlanUpdateStatus } from "./PlanningPageMainContent.interface";
import "./PlanningPageMainContent.scss";
import { PlanningPageMenu } from "../planningPageMenu/PlanningPageMenu";
import { HelpIcon } from "../../../../../../constants/icon";
import { useDateHelpers } from "../../../../../../hooks";

export const PlanningPageMainContentView = forwardRef((props: IPlanningPageMainContentView, ref) => {
  const url = useLocation();
  const params = useParams();
  const { t } = useTranslation();
  const padding = (url.pathname == "/communication/my-plan" && "pt-3") || (params.id && "pt-3");

  // onUpdatePlanStatus={handleUpdatePlanStatus}

  const handlePlanReject = useCallback(() => {
    props.onUpdatePlanStatus(PlanUpdateStatus.rejected);
  }, []);

  const handlePlanApproved = useCallback(() => {
    props.onUpdatePlanStatus(PlanUpdateStatus.approved);
  }, []);

  const { formatTimeSpan } = useDateHelpers();

  const getTimeFormat = (time: string) => {
    return formatTimeSpan(time, {
      formatWithoutDays: true
    }) || 0;
  }


  return (
    <>
      <PlanningRejectDialog
        isOpen={props.isOpen}
        onOpeningChange={props.onIsOpenChange}
        onPlanReject={handlePlanReject}
        isLoadingButton={props.isLoadingButton!}
        plan={props.plan}
      />
      <SelectApproverDialog
        open={props.isOpenApproverDialog}
        onClose={() => props.isOpenApproverDialogChange(false)}
        onSuccess={props.onApproverSuccess}
      />
      <div className={`planning-page-main-content__wrapper ${padding} pl-4 pr-4`}>
        {/*<div className="d-stack-row">*/}
        {/*  <div*/}
        {/*    className="d-stack-row align-center full-width"*/}
        {/*    // style={params.id ? { width: "50%" } : { width: "25%" }}*/}
        {/*  >*/}
        {/*    {!params.id && !props.approvalPlan ? (*/}
        {/*      props.isHideSidebar ? (*/}
        {/*        <div className="planning-page-main-content__icon mr-4 d-stack-row align-center justify-center">*/}
        {/*          <BsLayoutSidebar*/}
        {/*            size={22}*/}
        {/*            onClick={props.onHidingSidebarChange}*/}
        {/*            className="planning-page-main-content__arrow-button"*/}
        {/*          />*/}
        {/*          /!*<IconButton onClick={props.onHidingSidebarChange} children={<Icon component={() => <FiSidebar />} />} />*!/*/}
        {/*        </div>*/}
        {/*      ) : null*/}
        {/*    ) : null}*/}
        {/*    <Text weight="bold" className="planning-page-main-content__title mr-6">*/}
        {/*      {params.id*/}
        {/*        ? `${t("ui:subheader.week_plan")} ${formatDateToDateString(*/}
        {/*            new Date(props.plan?.dateFrom as string)*/}
        {/*          )} - ${formatDateToDateString(new Date(props.plan?.dateTo as string))}`*/}
        {/*        : t("ui:subheader.week_plan")}*/}
        {/*    </Text>*/}
        {/*    {url.pathname == "/communication/my-plan" && (*/}
        {/*      <AutocompleteAsync*/}
        {/*        style={{ width: "335px" }}*/}
        {/*        type="plan"*/}
        {/*        value={props.currentPlanId}*/}
        {/*        onChange={props.onPlanIdChange}*/}
        {/*        requestOptions={{ userId: props.userId, orderBy: "date_created", orderByType: "desc" }}*/}
        {/*        showSearch={false}*/}
        {/*      />*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*  <div className="d-stack-row justify-end align-center">*/}
        {/*    {url.pathname == "/communication/my-plan" && (*/}
        {/*      <div*/}
        {/*        style={{ background: colorScheme.backgroundColor, color: colorScheme.textColor }}*/}
        {/*        className="planning-page-main-content__indicator d-flex justify-center align-center"*/}
        {/*      >*/}
        {/*        {props.plan?.statusName}*/}
        {/*      </div>*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*  {url.pathname != "/communication/my-plan" && (*/}
        {/*    <div className="d-stack-row align-center">*/}
        {/*      <UserCard userInfo={props.plan?.user} />*/}
        {/*    </div>*/}
        {/*  )}*/}
        {/*</div>*/}
        {/*{url.pathname == "/communication/my-plan" && props.plan?.canBeMigratedFromPlanId ? (*/}
        {/*  <span onClick={props.onMigrateIssuesToPlan} className={`planning-page-main-content__text`}>*/}
        {/*    {t("text:transfer_undone_tasks")}*/}
        {/*  </span>*/}
        {/*) : null}*/}
        <PlanningPageMenu
          plan={props.plan}
          isSidebarOpen={props.isSidebarOpen}
          onSidebarOpenChange={props.onSidebarOpenChange}
          currentPlanId={props.currentPlanId}
          userId={props.userId}
          onPlanIdChange={props.onPlanIdChange}
          onMigrateIssuesToPlan={props.onMigrateIssuesToPlan}
        />
        {/*props.plan = {!!props.plan ? 1 : 0} <br/>*/}
        {/*props.issues.length = { props.issues.length } <br/>*/}
        {/*props.scheduleIssues.length = { props.scheduleIssues.length } <br/>*/}
        {props.plan && props.issues.length != 0 && !props.isLoading && (
          // d-stack-row justify-center align-center
          <div className="" >
            <div className="mt-4">
              <PlanningCollapseContent
                issues={props.issues}
                // scheduleIssues={props.scheduleIssues}
                plan={props.plan}
                onLoadRestart={props.onLoadRestart}
                onIssueEdit={props.onIssueEdit}
                onIssueToPlanCreate={props.onIssueToPlanCreate}
                // issuesRestart={props.issuesRestart}
                weekReportStart={props.weekReportStart}
                userId={props.userId}
              />
              {/*TODO: Rewrite everything!*/}
              <Card
                variant="secondary"
                className="planing-info-block"
              >
                <Text className="mr-55">{t("common:planning.summary_by_function")}</Text>
                <div className="planing-info-wrap">
                  <div
                    className={`planing-info-item`}
                  >
                    <div className="planing-info-item__title">
                      {t("ui:time_block.total_tasks")}
                      <Tooltip title={t("ui:tooltip.table_planing.total_tasks")}>
                        <Icon className="planing-info-item__icon" component={() => <HelpIcon />} />
                      </Tooltip>
                    </div>
                    <div className="planing-info-item__value">
                      <span>{props.plan?.issuesDone ?? 0} </span> / {props.plan?.issuesOverall ?? 0}
                    </div>
                  </div>
                  <div
                    className={`planing-info-item`}
                  >
                    <div className="planing-info-item__title">
                      {t("ui:time_block.plan_actual")}
                      <Tooltip title={t("ui:tooltip.table_planing.plan_actual")}>
                        <Icon className="planing-info-item__icon" component={() => <HelpIcon />} />
                      </Tooltip>
                    </div>
                    <div className="planing-info-item__value">
                      <span>
                        {getTimeFormat(props.plan?.timeFactOverall ?? "00:00:00")}
                      </span>
                      {' / '}
                      {getTimeFormat(props.plan?.timePlanOverall ?? "00:00:00")}

                    </div>
                  </div>
                </div>
              </Card>
              <div className="mt-6">
                <div className="mt-4 mb-6 d-stack-row justify-end align-center">
                  {params.id && !props.approvalPlan && (
                    <Button onClick={props.onIssueToPlanCreate} type="default" style={{ color: "#5672ff" }}>
                      {t("ui:button.create_task")}
                    </Button>
                  )}
                  {props.approvalPlan && (
                    <>
                      <Button
                        onClick={() => props.onIsOpenChange(true)}
                        className="mr-3"
                        type="default"
                      >
                        {t("ui:button.reject")}
                      </Button>
                      <Button
                        // onClick={() =>
                        //   props.onPlanStatusEdit({ ...props.plan, status: 3 }, t("notifier:success.plan_approved"))
                        // }
                        onClick={handlePlanApproved}
                        loading={props.isLoadingButton}
                      >
                        {t("ui:button.approve")}
                      </Button>
                    </>
                  )}
                  {url.pathname == "/communication/my-plan" && (
                    <>
                      <Button
                        disabled={props.plan.status == 2}
                        onClick={props.onIssueToPlanCreate}
                        type="default"
                        style={{ color: "#5672ff" }}
                      >
                        {t("ui:button.create_task")}
                      </Button>
                      {props.plan.status == 2 || props.plan.status == 3 ? null : (
                        <Button
                          className="ml-3"
                          onClick={() => props.isOpenApproverDialogChange(true)}
                          loading={props.isLoadingButton}
                        >
                          {t("ui:button.submit_for_approval")}
                        </Button>
                      )}
                    </>
                  )}
                </div>
                <PlanHistoryModule ref={ref} planId={props.plan?.id} />
              </div>
            </div>
          </div>
        )}
      </div>
      {props.plan && !props.issues.length && !props.isLoading && (
        <div className="empty-block-wrapper">
          <div className="d-stack-row justify-center align-center" >
            <Empty
              image={<img src={window.location.origin + "/planning-empty.png"} alt="Empty content" />}
              imageStyle={{ height: 300, width: 338, margin: "0 auto" }}
              description={
                <div className="d-stack-column justify-center align-center mt-12" style={{ width: "420px" }}>
                  <Text weight={"bold"} size="16px">
                    {t("text:no_open_tasks.content")}
                  </Text>
                  <Button onClick={props.onIssueToPlanCreate} className="mt-12">
                    {t("ui:button.create_task")}
                  </Button>
                </div>
              }
            />
          </div>
        </div>
      )}
      {!props.plan && !props.isLoading && (
        <div className="empty-block-wrapper">
          <div className="d-stack-row justify-center align-center">
            <EmptyBlock />
          </div>
        </div>
      )}
    </>
  );
});
