import React from "react";
import { useClaims, useCopyToClipboard, useRootStore } from "../../../hooks";
import { Avatar, Icon, IconButton, Link, Popover, Text } from "../../uiKit";
import { useNavigate } from "react-router-dom";
import { UsertButton } from "./components/userButton/UsertButton";
import { UserDto, UserShortDto } from "../../../api";
import { ITooltip } from "../../uiKit/dataDisplay/tooltip/Tooltip";
import { removeAllOccurrencesExceptFirstOne, removeMultipleSpacesFromString } from "../../../helpers/stringFunctions";
import { theme } from "antd";
import { parsePhoneNumber } from "awesome-phonenumber";
import { LucideAtSign, LucideCopy, LucidePhone, LucideSend } from "lucide-react";
import { permissionKeys } from "../../../utils/permissions";
import { useUserNameFormat } from "../../../hooks/useUserNameFormat";
// import { useUserNameFormat } from "../../../hooks/useUserNameFormat";

export interface IUserTooltipView {
  children: React.ReactNode;
  user?: UserDto | UserShortDto | null;
  tooltipProps?: ITooltip;
  zIndex?: number;
}

export const UserTooltipView = ({ children, user, tooltipProps, zIndex }: IUserTooltipView) => {
  const { issueInitDataStore } = useRootStore();
  const navigate = useNavigate();
  const claims = useClaims();
  const { useToken } = theme;
  const { token } = useToken();
  const [copyFn] = useCopyToClipboard();

  // const { getAvatarText, getFullName } = useUserNameFormat();

  const getAvatarProps = () => ({
    color: user?.color,
    src: user?.avatar?.image?.url,
    size: 60,
    text: [user?.lastName, user?.firstName],

    // text: getAvatarText(user?.lastName ?? "", user?.firstName ?? ""),
  });

  // const getTooltipContent = () => {
  //   const userName = removeMultipleSpacesFromString(
  //     ((user?.name ?? "")?.trim().length == 0
  //       ? getFullName(
  //         user?.lastName ?? "",
  //         user?.firstName ?? "",
  //         user?.middleName ?? ""
  //       ) ?? user?.nameFallback
  //       : user?.name
  //     )?.trim() ?? ""
  //   ).split(" ");

  const { getFullName } = useUserNameFormat();

  const getTooltipContent = () => {
    const userName = removeMultipleSpacesFromString(
      ((user?.name ?? "")?.trim().length == 0
        ? getFullName(user?.lastName ?? "", user?.firstName ?? "", user?.middleName)
        : user?.name
      )?.trim() ?? ""
    ).split(" ");


    const userPhoneNumber = (phoneNumber: string | null | undefined): string | null => {
      if (phoneNumber == null || phoneNumber.trim().length == 0) return null;
      const normalizedPhoneNumber = parsePhoneNumber(
        removeAllOccurrencesExceptFirstOne("+" + ("+" + phoneNumber ?? ""), "\\+")
      ).number?.international;
      if (normalizedPhoneNumber == undefined) return phoneNumber;
      return normalizedPhoneNumber;
    };

    return (
      // TODO: Check
      <div style={{ maxWidth: "fit-content" }} onClick={(event) => event.stopPropagation()}>
        <div className="d-stack-row spacing-4">
          {/*<div className="d-stack-row spacing-4">*/}
          <Avatar {...getAvatarProps()} />
          {/*</div>*/}
          <div className="d-stack-column">
            <div className="d-stack-row spacing-1">
              {userName.map((text, index) => (
                <Text key={index} className="user-tooltip_name" children={text} />
              ))}
            </div>
            {(user?.email ?? "").trim().length > 0 && (
              <div className="user-tooltip_link d-stack-row spacing-1 align-center">
                <Icon component={() => <LucideAtSign color={token.colorTextSecondary} size={16} />} className="mr-1" />
                <Link style={{ color: token.colorTextSecondary }} href={`mailto:${user?.email}`}>
                  {user?.email}
                </Link>
                <IconButton icon={<LucideCopy size={16} />} onClick={() => copyFn(user?.email ?? "")} />
              </div>
            )}
            {user?.contact?.value && (user?.contact?.uriLink ?? "").trim().length > 0 && (
              <div className="user-tooltip_link d-stack-row spacing-1 align-center">
                {/*<Icon fontSize="small" sx={{ alignSelf: "baseline", overflow: "visible" }}>*/}
                <Icon component={() => <LucideSend color={token.colorTextSecondary} size={16} />} className="mr-1" />
                {/*</Icon>*/}
                <Link
                  style={{ color: token.colorTextSecondary }}
                  href={user?.contact?.uriLink ?? undefined}
                  target="_blank"
                >
                  {user?.contact?.value}
                </Link>
                <IconButton icon={<LucideCopy size={16} />} onClick={() => copyFn(user?.contact?.uriLink ?? "")} />
              </div>
            )}
            {(user?.phoneNumber ?? "").trim().length > 0 && claims.has(permissionKeys.user.viewUserPhoneNumber) && (
              <div className="user-tooltip_link d-stack-row spacing-1 align-center">
                {/*<Icon fontSize="small" sx={{ alignSelf: "baseline", overflow: "visible" }}>*/}
                <Icon component={() => <LucidePhone color={token.colorTextSecondary} size={16} />} className="mr-1" />
                {/*</Icon>*/}
                <Link style={{ color: token.colorTextSecondary }} href={`tel:${userPhoneNumber(user?.phoneNumber)}`}>
                  {userPhoneNumber(user?.phoneNumber)}
                </Link>
                <IconButton
                  icon={<LucideCopy size={16} />}
                  onClick={() => copyFn(userPhoneNumber(user?.phoneNumber) ?? "")}
                />
              </div>
            )}
            <div className="mt-2">
              <UsertButton user={user} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Popover
      // placement="bottomLeft"
      mouseEnterDelay={0.5}
      overlayClassName="user-tooltip"
      content={getTooltipContent()}
      zIndex={zIndex}
      {...tooltipProps}
    >
      {children}
    </Popover>
  );
};
